<template>
<div id="scope" class="scope">
    <b-row cols="3">
        <b-col>
            <p class="font15"><b>{{guide}}</b></p>
            <div v-for="(gd,index) in guideDetail" :key="index" class="ml-2 font14">
                <span @click="openPage(gd.link)" class="link">{{gd.name}}</span>
            </div>
        </b-col>
        <b-col>
            <p class="font15"><b>{{aboutUs}}</b></p>
            <div v-for="(ad,index) in aboutUsDetail" :key="index" class="ml-2 font14">
                <span @click="openPage(ad.link)" class="link">{{ad.name}}</span>
            </div>
        </b-col>
        <b-col>
            <p class="font15"><b>{{policy}}</b></p>
            <div v-for="(pd,index) in policyDetail" :key="index" class="ml-2 font14">
                <span @click="openPage(pd.link)" class="link">{{pd.name}}</span>
            </div>
        </b-col>
    </b-row>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import router from '../../router';
export default {
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    mounted() {
        this.checkMode()
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    data() {
        return {
            guide: 'คู่มือการใช้งานเว็บไซต์',
            guideDetail: [{
                    name: 'คู่มือการใช้งาน',
                    link: '/guide'
                },
                {
                    name: 'คู่มือนักเขียน',
                    link: '/writer'
                },
                {
                    name: 'ระบบทอง',
                    link: '/paymentGuide'
                }
            ],
            aboutUs: 'เกี่ยวกับเรา',
            aboutUsDetail: [{
                    name: 'จุดประสงค์การก่อตั้ง NL',
                    link: '/objective'
                },
                {
                    name: 'ติดต่อเรา',
                    link: '/contact'
                },
            ],
            policy: 'นโยบาย',
            policyDetail: [{
                    name: 'ข้อตกลงในการใช้งาน',
                    link: '/policy/1'
                },
                {
                    name: 'นโยบายการคุ้มครองข้อมูล',
                    link: '/policy/2'
                },
                {
                    name: 'นโยบายการคืนเงิน',
                    link: '/policy/3'
                },
                {
                    name: 'ทรัพย์สินทางปัญญา',
                    link: '/policy/4'
                },
                {
                    name: 'นโยบายความเป็นส่วนตัว',
                    link: '/policy/5'
                }
            ]
        }
    },
    methods: {
        openPage(link) {
            router.push(link)
        },
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('scope').classList.remove('darkmode')
        },
        darkmode() {
            document.getElementById('scope').classList.add('darkmode')
        }
    }
};
</script>

<style scoped>
.scope {
    background-color: #ffffff;
    padding: 2% 20% 2% 26%;
}

.darkmode {
    background-color: #35363A;
}

.font15 {
    font-size: 15px;
}

.font14 {
    font-size: 14px;
}

.link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.onLoadPage {
    position: absolute;
    bottom: 0px;
    width: 100%
}

@media screen and (max-width: 500px) {
    .scope {
        padding: 3%;
    }
}
</style>
